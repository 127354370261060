import React from 'react'
import tw from 'twin.macro'

const StyledInput = tw.input`
  uppercase
  text-center
  bg-sand
  rounded-full
  p-3
  md:mt-6
  border-b-2
  focus:outline-none
  mr-2
  w-full
  lg:w-72
`

export default function SearchBox({ onChange, value, placeholder }) {
  return (
    <StyledInput
      onChange={onChange}
      value={value}
      type="text"
      placeholder={placeholder}
    />
  )
}
