import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import tw from 'twin.macro'
import SearchBox from '../components/SearchBox'
import DynamicComponent from '../components/DynamicComponent'

// GQL fragments
import '../fragments/HeroContentQuery'
import '../fragments/HeroBlogContentQuery'
import '../fragments/TextImageContentQuery'
import '../fragments/OneColContentQuery'
import '../fragments/TwoColsContentQuery'
import '../fragments/ThreeColsContentQuery'
import '../fragments/TextImageSquaresContentQuery'
import '../fragments/QuoteContentQuery'
import '../fragments/StaticImageVideoContentQuery'
import '../fragments/TemplateElementContentQuery'
import '../fragments/DivideContentQuery'
import '../fragments/ComparisonTableContentQuery'
import '../fragments/LeveringstiderContentQuery'
import '../fragments/FormsContentQuery'
import '../fragments/FaqContentQuery'

const ListItem = tw.div`
  mb-16
`

export default function JobsPage({ data }) {
  const [search, setSearch] = useState('')

  const pageInfo = data.craftPagesJobsEntry
  const blogEntries = data.allCraftJobsJobsEntry.nodes

  const ogImageOverride = pageInfo.ogImage[0]?.ogimage
  const ogImageGlobal = data.craftHeaderGlobalSet.ogImage[0]?.ogimage

  const ogImage = ogImageOverride || ogImageGlobal

  return (
    <Layout
      pageTitle={pageInfo.title}
      darkMenu={pageInfo.darkMenu}
      pageDesc={pageInfo.metaDescription}
      ogImage={ogImage}
    >
      <div css={tw`container md:px-10 px-6`}>
        <div css={tw`max-w-screen-md mb-14`}>
          <div
            css={tw`md:mt-64 mt-40`}
            dangerouslySetInnerHTML={{
              __html: pageInfo.column1,
            }}
          />

          <DynamicComponent
            contentTypes={pageInfo.dynamicContent}
            slug={pageInfo.slug}
            title={pageInfo.title}
          />

          <SearchBox
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Søg"
          />
        </div>
        <section className="grid" css={tw`mb-16 max-w-screen-lg`}>
          {blogEntries
            .filter((entry) => {
              return (
                entry.title.toLowerCase().includes(search.toLowerCase()) ||
                entry.description.toLowerCase().includes(search.toLowerCase())
              )
            })
            .map((entry, i) => {
              return (
                <ListItem key={i}>
                  <Link
                    to={'/' + entry.uri}
                    css={tw`w-full h-full text-navy block hover:relative hover:z-10 hover:scale-105 transform transition ease-in duration-300`}
                  >
                    <h3 css={tw`text-4xl pb-4`}>{entry.title}</h3>

                    <p
                      dangerouslySetInnerHTML={{ __html: entry.description }}
                    />

                    <p className="link">{entry.title}</p>
                  </Link>
                </ListItem>
              )
            })}
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    craftHeaderGlobalSet {
      ogImage {
        ogimage
      }
    }
    craftPagesJobsEntry(slug: { eq: $slug }) {
      title
      slug
      darkMenu
      column1
      metaDescription
      ogImage {
        ogimage
      }
      dynamicContent {
        __typename
        ...HeroContent
        ...HeroBlogContent
        ...TextImageContent
        ...TextImageSquaresContent
        ...OneColContent
        ...TwoColsContent
        ...ThreeColsContent
        ...QuoteContent
        ...StaticImageVideoContent
        ...TemplateElementContent
        ...DivideContent
        ...ComparisonTableContent
        ...LeveringstiderContent
        ...FormsContent
        ...FaqContent
      }
    }
    allCraftJobsJobsEntry(
      sort: { fields: dateCreated, order: DESC }
      filter: { enabled: { eq: true } }
    ) {
      nodes {
        title
        description
        uri
        dynamicContent {
          ... on Craft_dynamicContent_hero_BlockType {
            backgroundImage {
              title
              ... on Craft_productImages_Asset {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
